import Amplify, {API, graphqlOperation} from 'aws-amplify';
import {getPartnerAuthToken} from '../../redux/actions/user';
import {getAnswer} from './CustomQueries';
import {IAnswerRepository} from './IAnswerRepository';

export class AnswerRepository implements IAnswerRepository {
  constructor() {}

  async getAnswer(id: string): Promise<any> {
    let response = await API.graphql(
      graphqlOperation(
        getAnswer,
        {
          id,
        },
        getPartnerAuthToken(),
      ),
    );

    console.log('response->getAnswer', response);
    //@ts-ignore
    return response.data.answer;
  }
}
