import React from 'react';
import {Image, Typography} from 'antd';
import moment from 'moment';
import Boy from '../../assets/icons/Boy02.svg';
import PikadoSmallLogo from '../../assets/icons/PikadoSmallLogo.svg';
import {getGradeByScore, getGradeColor} from '../../constants';
import {Classschool, School, Statistic} from '../../graphql/API';

const {Text} = Typography;

type ReportHeaderProps = {
  school: School;
  selectedClass: Classschool;
  report: Statistic;
};

export const ReportHeader: React.FC<ReportHeaderProps> = ({
  school,
  selectedClass,
  report,
}) => {
  return (
    <div
      className="reportHeader-grid-columns"
      style={{
        border: '2px solid #ECECEC',
        display: 'grid',
      }}>
      <div style={{display: 'grid', gridTemplateColumns: '0.1fr 1fr'}}>
        <Image
          width={100}
          preview={false}
          src={school.promo?.thumbnailUri ? school.promo?.thumbnailUri : Boy}
          style={{display: 'inline-block'}}
          wrapperStyle={{verticalAlign: 'top', padding: 10}}
        />
        <div style={{marginLeft: 20, alignSelf: 'center'}}>
          <Text
            style={{
              display: 'block',
              fontFamily: 'Arial Rounded MT Bold',
              fontSize: 21,
            }}>
            {selectedClass?.name}
          </Text>
          <Text style={{display: 'block', fontFamily: 'Arial Rounded MT Bold'}}>
            Students in Class {selectedClass.students.length}
          </Text>
          <Text
            style={{
              display: 'block',
              fontFamily: 'Arial Rounded MT Bold',
              color: '#999999',
            }}>
            Since {moment(school.createdAt).format('MMM. YYYY')}
          </Text>
        </div>
      </div>
      <div
        className="grade-border"
        style={{
          display: 'grid',
          gridTemplateColumns: '0.5fr 1fr',
          position: 'relative',
        }}>
        <div
          style={{
            backgroundColor: getGradeColor(
              selectedClass.statistic?.averageScore
                ? selectedClass.statistic?.averageScore
                : 0,
            ),
            position: 'relative',
          }}>
          <Text
            style={{
              color: '#FFFFFF',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              fontSize: 25,
              fontFamily: 'Arial Rounded MT Bold',
            }}>
            {getGradeByScore(
              selectedClass.statistic?.averageScore
                ? selectedClass.statistic?.averageScore
                : 0,
            )}
          </Text>
        </div>
        <div style={{alignSelf: 'center', marginLeft: 20}}>
          <Text
            style={{
              fontFamily: 'Arial Rounded MT Bold',
              display: 'block',
              fontSize: 21,
              color: '#999999',
            }}>
            Total performance
          </Text>
          <Text
            style={{
              fontFamily: 'Arial Rounded MT Bold',
              fontSize: 27,
              color: '#40B2F2',
            }}>
            {report?.averageScore}%
          </Text>
        </div>
      </div>
      <div style={{display: 'grid', gridTemplateColumns: '0.1fr 1fr'}}>
        <Image width={100} preview={false} src={PikadoSmallLogo} />
        <div style={{alignSelf: 'center'}}>
          <Text
            style={{
              fontFamily: 'Arial Rounded MT Bold',
              color: '#999999',
              marginLeft: 20,
              alignSelf: 'center',
              display: 'block',
            }}>
            PikaDo Daily Exercise Report
            <br />
            Version 1.0 Generated on:{`\n`}
          </Text>
          <Text
            style={{
              fontFamily: 'Arial Rounded MT Bold',
              color: '#EF426B',
              marginLeft: 20,
            }}>
            {moment(report?.fullReportLastGeneratedDate).format(
              'ddd. DD.MM.YYYY',
            ) === 'Invalid date'
              ? 'No reports generated yet.'
              : moment(report?.fullReportLastGeneratedDate).format(
                  'ddd. DD.MM.YYYY',
                )}
          </Text>
        </div>
      </div>
    </div>
  );
};
