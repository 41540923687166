export const getGradeByScore = (score: number) => {
    if (score >= 90 && score <= 100) {
      return 'A+';
    } else if (score >= 85 && score <= 89) {
      return 'A';
    } else if (score >= 80 && score <= 84) {
      return 'A-';
    } else if (score >= 75 && score <= 79) {
      return 'B+';
    } else if (score >= 70 && score <= 74) {
      return 'B';
    } else if (score >= 65 && score <= 69) {
      return 'C+';
    } else if (score >= 60 && score <= 64) {
      return 'C';
    } else if (score >= 55 && score <= 59) {
      return 'D+';
    } else if (score >= 50 && score <= 54) {
      return 'D';
    } else if (score >= 40 && score <= 49) {
      return 'E';
    } else {
      return 'F';
    }
  };

  export const getGradeColor = (score: number) => {
    if (score >= 90 && score <= 100) {
      return '#C3E56E';
    } else if (score >= 85 && score <= 89) {
      return '#FFD252';
    } else if (score >= 80 && score <= 84) {
      return '#40B2F2';
    } else if (score >= 75 && score <= 79) {
      return '#EF426B';
    } else if (score >= 70 && score <= 74) {
      return '#F772FA';
    } else if (score >= 65 && score <= 69) {
      return '#D7D7D7';
    } else if (score >= 60 && score <= 64) {
      return '#B4B4B4';
    } else if (score >= 55 && score <= 59) {
      return '#808080';
    } else if (score >= 50 && score <= 54) {
      return '#2F2F2F';
    } else if (score >= 40 && score <= 49) {
      return '#D9D9D9';
    } else {
      return '#D9D9D9';
    }
  };