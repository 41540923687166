import {Link, useHistory} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {Button, Image, Menu, Typography} from 'antd';
import {Auth} from 'aws-amplify';
import {useDispatch} from 'react-redux';
import Pikado from '../assets/icons/PikaDoLogo.png';
import ResponsiveAppBar from './Appbar';

const {Text} = Typography;

export const SiderComponent = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {}, []);

  const RenderTabs = () => (
    <div style={{alignItems: 'flex-start', display: 'flex'}}>
      <Menu.Item key="home">
        <Link to={'/home'}>Home</Link>
      </Menu.Item>
    </div>
  );
  return (
    <div>
      <ResponsiveAppBar />
      {/* <img
        className="pikado-image"
        alt="s"
        src={Pikado}
        style={{
          resize: 'inline',
          width: '12%',
          height: '10%',
          alignSelf: 'center',
        }}
      />
      <Text
        style={{
          alignSelf: 'flex-end',
          color: 'white',
          fontWeight: 'bold',
          textAlign: 'left',
          marginInline: 4,
          marginInlineEnd: 20,
        }}>
        Schools
      </Text>
      <RenderTabs />
      <Menu.Item style={{}} key="signOut">
        <Button
          style={{width: 150, fontWeight: 'bold'}}
          onClick={async () => {
            await Auth.signOut();
            dispatch({type: 'RESET_USER'});
            history.go(0);
          }}>
          Logout
        </Button>
      </Menu.Item> */}
    </div>
  );
};
