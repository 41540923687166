import {Auth, API, graphqlOperation} from 'aws-amplify';

import {CognitoUser, ISignUpResult} from 'amazon-cognito-identity-js';
import {customMe} from '../customQueries/user';
import {validatePartnerAuthUser} from '../../graphql/mutations';
import {AuthenticatorResponse} from '../../graphql/API';
import {getPartnerAuthToken, getPartnerCognitoId} from '../actions/user';

export interface IAuthRepository {
  signIn(userName: string, password: string): Promise<CognitoUser | any>;
  signOut(): Promise<any>;
  signUp(email: string, password: string): Promise<ISignUpResult | Error>;
  validatePartnerAuth(
    partnerAppName: string,
    teacherId: string,
  ): Promise<AuthenticatorResponse>;

  getCurrentUser(): Promise<any>;
}

export class AuthRepository implements IAuthRepository {
  async getCurrentUser(): Promise<any> {
    if (getPartnerCognitoId()) {
      const res = await API.graphql(
        graphqlOperation(
          customMe,
          {
            cognitoUserId: getPartnerCognitoId(),
          },
          getPartnerAuthToken(),
        ),
        {
          identity: getPartnerCognitoId(),
        },
      );
      //@ts-ignore
      return res.data.me;
    } else {
      const res = await API.graphql(
        graphqlOperation(customMe, undefined, getPartnerAuthToken()),
      );

      //@ts-ignore
      return res.data.me;
    }
  }

  async signIn(userName: string, password: string): Promise<CognitoUser | any> {
    return await Auth.signIn({
      username: userName,
      password: password,
      validationData: {
        typeLogin: 'Schools',
      },
    });
  }

  async validatePartnerAuth(
    partnerAppName: string,
    teacherId: string,
  ): Promise<AuthenticatorResponse> {
    let token: any = await API.graphql(
      graphqlOperation(
        validatePartnerAuthUser,
        {
          input: {
            partnerId: teacherId,
            partnerAppName,
          },
        },
        'partner_auth-' + partnerAppName,
      ),
    );
    return token.data.validatePartnerAuthUser;
  }

  async signOut(): Promise<any> {
    return await Auth.signOut();
  }

  async signUp(
    userName: string,
    password: string,
  ): Promise<ISignUpResult | Error> {
    return await Auth.signUp({
      username: userName,
      password: password,
    });
  }
}
