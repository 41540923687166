import {IStatisticRepository} from './IStatisticRepository';
import Amplify, {graphqlOperation} from 'aws-amplify';
import {generateClassFullReport} from '../../graphql/mutations';
import {fullReport, minimalStudent, studentsTable} from './CustomQueries';
import {getPartnerAuthToken} from '../../redux/actions/user';

export class StatisticRepository implements IStatisticRepository {
  constructor() {}

  async generateClassFullReport(classschoolId: string): Promise<any> {
    let response = await Amplify.API.graphql(
      graphqlOperation(
        generateClassFullReport,
        {
          classschoolId: classschoolId,
        },
        getPartnerAuthToken(),
      ),
    );
    console.log('response from generateClassFullReport->', response);
    return response.data.generateClassFullReport;
  }

  async getFullReport(statisticId: string): Promise<any> {
    let response = await Amplify.API.graphql(
      graphqlOperation(
        fullReport,
        {
          id: statisticId,
        },
        getPartnerAuthToken(),
      ),
    );
    console.log('response->', response);
    return response.data.statistic;
  }

  async getStudentById(studentId: string): Promise<any> {
    let response = await Amplify.API.graphql(
      graphqlOperation(
        minimalStudent,
        {
          id: studentId,
        },
        getPartnerAuthToken(),
      ),
    );
    console.log('response->', response);
    return response.data.student;
  }
  async getStudentsBySchoolId(
    schoolId: string,
    classId: string,
  ): Promise<any> {    
    let response = await Amplify.API.graphql(
      graphqlOperation(
        studentsTable,
        {
          filter:{
            schoolId: {
              eq: schoolId
            },
            classschoolId: {
              eq: classId
            }
          },
          // schoolId: schoolId,
          // classId: classId,
        },
        getPartnerAuthToken(),
      ),
    );
    console.log('response studentsss->', response);
    return response.data.students;
  }
}
